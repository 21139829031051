@import "@/styles/theme/main.scss";
.lottie {
  width: 100%;
  max-width: 400px;

  @include media-tablet {
    max-width: 180px;
  }
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.code {
  color: $text-primary;
  font-size: 32px;
  font-weight: bold;
}

.divider {
  color: $text-primary;
  font-size: 24px;
  padding-left: 8px;
  padding-right: 8px;
}

.text {
  color: $text-primary;
  font-size: 24px;
}

.button {
  max-width: 400px;
}
